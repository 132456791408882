<template>
  <b-modal
    id="addService"
    :title="t('Add Service')"
    hide-footer
    size="md"
  >
    <validation-observer ref="addService">
      <b-form
        autocomplete="off"
        @submit.prevent="submitAddService"
      >
        <!-- Service Name -->
        <b-form-group
          :label="t('Service Name')"
          label-for="vi-role"
          class="required"
        >
          <validation-provider
            #default="{ errors }"
            class="input-group my-1"
            name="Service Name"
            vid="vi-role"
            rules="required"
          >
            <b-form-input
              id="vi-role"
              v-model="form.service_name"
              autocomplete="off"
              :class="errors[0] ? 'border-red' : ''"
              :placeholder="t('Service Name')"
              maxlength="50"
            />
          </validation-provider>
        </b-form-group>

        <!-- Status -->
        <b-form-group
          :label="t('Status')"
          label-for="vi-status"
          class="required"
        >
          <validation-provider
            #default="{ errors }"
            class="input-group my-1"
            name="Status"
            vid="vi-status"
            rules="required"
          >
            <v-select
              v-model="form.status"
              class="p-0 border-0 form-control"
              :class="errors[0] ? 'border-red-vselect' : ''"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :reduce="status => status.value"
              placeholder="Select Status"
              :options="statusList"
            />
          </validation-provider>
        </b-form-group>

        <b-col
          cols="12"
          class="text-center pt-2"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            {{ t('Submit') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="$bvModal.hide('addService')"
          >
            {{ t('Cancel') }}
          </b-button>
        </b-col>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useUtils } from '@core/libs/i18n'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required } from '@validations'
import {
  failedToast, hideLoader, showLoader, status, successToast,
} from '@/utils/common'
import apis from '@/apis'
import store from '@/store'
import constants from '@/constants'

export default {
  name: 'AddService',
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: ['service_id'],
  data() {
    return {
      required,
      statusList: status,
      form: {
        service_id: '',
        service_name: '',
        status: '',
      },
    }
  },
  setup() {
    const { t } = useUtils()
    return { t }
  },
  watch: {
    service_id: {
      deep: true,
      handler(val) {
        this.getServiceById(val)
      },
    },
  },
  mounted() {
    if (this.service_id) {
      this.getServiceById(this.service_id)
    }
    this.$root.$on('bv::modal::shown', (a, componentId) => {
      // eslint-disable-next-line eqeqeq
      if (componentId == 'addService') {
        if (this.service_id) {
          this.getServiceById(this.service_id)
        }
      }
    })
    this.$root.$on('bv::modal::hidden', (a, componentId) => {
      // eslint-disable-next-line eqeqeq
      if (componentId == 'addService') {
        this.form = {
          service_id: '',
          service_name: '',
          status: '',
        }
      }
    })
  },
  methods: {
    submitAddService() {
      this.$refs.addService.validate().then(success => {
        if (success) {
          this.saveService()
        }
      })
    },
    async saveService() {
      showLoader()
      await store.dispatch('servicesStore/post', { url: apis.MASTER_ADD_SERVICE_LIST, data: this.form }).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          successToast(response.data.msg)
          hideLoader()
          this.$bvModal.hide('addService')
          this.$root.$emit('refreshServices')
        }
        hideLoader()
      }).catch(e => {
        hideLoader()
        failedToast(e.msg)
      })
    },
    getServiceById(id) {
      store.dispatch('servicesStore/post', { url: apis.MASTER_GET_SERVICE_BY_ID, data: { service_id: id } }).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.form.service_id = id
          this.form.service_name = response.data.data.service_name
          this.form.status = response.data.data.status
        }
        hideLoader()
      }).catch(() => {
        hideLoader()
      })
    },
  },
}
</script>

<style>

</style>
