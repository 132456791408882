<template>
  <div>
    <div class="d-flex justify-content-end mb-1">
      <b-button
        variant="primary"
        @click="$bvModal.show('addService')"
      >
        Add Service
      </b-button>
    </div>
    <b-card>
      <b-table
        sticky-header
        responsive="true"
        :items="items.data"
        :fields="userFields"
        @row-contextmenu="contextmenu"
        @sort-changed="sortChanged"
      >
        <template #cell(status)="data">
          <span
            v-if="data.item.status === constants.USER_STATUS_ACTIVE"
            class="text-success cursor-pointer"
            @click="changeStatus(data.item.status, data.item.enc_service_id )"
          >Active</span>
          <span
            v-if="data.item.status === constants.USER_STATUS_INACTIVE"
            class="text-danger cursor-pointer"
            @click="changeStatus(data.item.status, data.item.enc_service_id )"
          >In-Active</span>
        </template>
        <template
          #cell(reset_password)="data"
          :class="text-center"
        >
          <feather-icon
            icon="RefreshCwIcon"
            @click.prevent="resetPassword(data.item.adminuserid)"
          />
        </template>
        <template
          v-if="items.total == 0"
          #table-caption
        >
          <div
            class="text-center"
          >
            No Record Found
          </div>
        </template>
      </b-table>
      <b-row v-if="items.total">

        <b-col
          lg="4"
          class="py-2 d-flex"
        >
          <span class="pr-2 pt-10 align-self-center">
            Total Entries {{ items.total }}
          </span>

          <b-form-group class="mb-0 align-self-center">
            <label class="d-inline-block text-sm-left mr-50">Per page</label>
            <b-form-select
              id="perPageSelect"
              v-model="form.size"
              size="sm"
              :options="tableFilter.pageOptions"
              class="w-70"
              @change="getServiceList"
            />
          </b-form-group>

        </b-col>
        <b-col lg="8">
          <pagination
            :limit="2"
            :data="items"
            class="pt-1 justify-content-end"
            @pagination-change-page="getResults"
          />
        </b-col>

      </b-row>
    </b-card>

    <add-service :service_id="service_id" />

    <vue-context
      ref="UserContextMenu"
    >
      <li
        v-for="data in menuData"
        :key="data.id"
      >
        <b-link
          v-if="data.id == 1"
          class="d-flex align-items-center"
          @click="optionClicked(data.id)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
        <b-link
          v-else-if="data.id != 1"
          class="d-flex align-items-center"
          @click="optionClicked(data.id)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>
  </div>
</template>

<script>
import VueContext from 'vue-context'
import axios from 'axios'
import permissions from '@/permissions'
import {
  tableFilter, showLoader, hideLoader, failedToast, successToast,
} from '@/utils/common'
import checkPermissions from '@/utils/checkPermissons'
import store from '@/store'
import constants from '@/constants'
import apis from '@/apis'
import AddService from './AddService.vue'

export default {
  name: 'UserList',
  components: {
    VueContext,
    AddService,
  },
  data() {
    return {
      constants,
      tableFilter,
      permissions,
      items: '',
      resetPasswordId: 0,
      service_id: '',
      form: {
        params: {
          status: '',
          service_name: '',
        },
        size: '10',
        totalElements: '',
        totalPages: '',
        sortBy: 'id',
        sortOrder: 'desc',
      },
    }
  },
  computed: {
    userFields() {
      return [
        { key: 'id', label: this.$i18n.t('Id'), sortable: true },
        { key: 'service_name', label: this.$i18n.t('Service Name'), sortable: true },
        { key: 'status', label: this.$i18n.t('Status'), sortable: true },
        { key: 'created_by_name', label: this.$i18n.t('Created By'), sortable: true },
        { key: 'created_at', label: this.$i18n.t('Created At'), sortable: true },
      ]
    },
    menuData() {
      return [
        { id: 1, icon: 'EditIcon', text: this.$i18n.t('Edit Service') },
      ]
    },
  },
  watch: {
    items() {
      return this.items
    },
  },
  mounted() {
    this.$root.$on('refreshServices', () => {
      this.getServiceList()
    })
    this.getServiceList()
  },
  created() {
    // this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', true)
  },
  methods: {
    checkPermission(id) {
      return checkPermissions(id)
    },
    sortChanged(val) {
      this.form.sortBy = val.sortBy
      this.form.sortOrder = val.sortDesc ? 'desc' : 'asc'
      this.getServiceList()
    },
    async getServiceList() {
      showLoader()
      await store.dispatch('servicesStore/post', { url: apis.MASTER_GET_SERVICE_LIST, data: this.form }).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          this.items = response.data.data
          hideLoader()
        }
      }).catch(e => {
        hideLoader()
        failedToast(e.msg)
      })
      hideLoader()
    },
    getResults(page = 1) {
      showLoader()
      // eslint-disable-next-line no-undef
      axios.post(`${apis.MASTER_GET_SERVICE_LIST}?page=${page}`, this.form, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
        .then(response => {
          this.items = response.data.data
        })
      hideLoader()
    },
    contextmenu(item, index, event) {
      event.preventDefault()
      this.values = item
      this.service_id = item.enc_service_id
      this.$refs.UserContextMenu.open(event, item)
    },
    optionClicked(id) {
      if (id === 1) {
        this.$bvModal.show('addService')
      }
    },
    changeStatus(status, id) {
      // eslint-disable-next-line eqeqeq
      if (status == 'A') {
        this.$swal({
          title: this.$i18n.t('Are you sure ?'),
          text: this.$i18n.t('You want to Deactivate this Service!'),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$i18n.t('Cancel'),
          confirmButtonText: this.$i18n.t('Yes'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.changeServiceStatus(id, 'I')
          }
        })
      } else {
        this.$swal({
          title: this.$i18n.t('Are you sure ?'),
          text: this.$i18n.t('You want to Activate this Service!'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$i18n.t('Yes'),
          cancelButtonText: this.$i18n.t('Cancel'),
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.changeServiceStatus(id, 'A')
          }
        })
      }
    },
    async changeServiceStatus(id, status) {
      showLoader()
      await store.dispatch('servicesStore/post', { url: apis.MASTER_SERVICE_CHANGE_STATUS, data: { service_id: id, status } }).then(response => {
        // eslint-disable-next-line eqeqeq
        if (response.data.code == constants.SUCCESS) {
          hideLoader()
          successToast(response.data.msg)
          this.getServiceList()
        }
        hideLoader()
      }).catch(e => {
        failedToast(e.msg)
        hideLoader()
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-context.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
